var $window = $(window);
var $html = $('html');

if (!$html.hasClass('hasStickyNav')) {
  $window.on('scroll', function() {
    if ($window.scrollTop() > 0) {
      $html.addClass('hasStickyNav');
    } else {
      $html.removeClass('hasStickyNav');
    }
  });
}

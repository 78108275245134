module.exports=[{
    "name": "Ann Claesen",
    "date": "07-26-2017",
    "score": 10,
    "body": "\n  Het was een zalig weekend in Spoor62! Waar vroeger een trein voorbijraasde, genoten we nu van een oase van rust. We werden ontvangen in een prachtige b&b, tot in de kleinste details met smaak ingericht en uitgebaat door Irma die met passie haar gastenverblijf\n  runt. Dat vertaalt zich in een warm onthaal, een met liefde gemaakt en zeer gevarieerd en lekker ontbijt en tips op maat van de bezoeker. Daarenboven genoten wij van de privé-yogasessies op het terras onder de ruisende bomen, de fietstocht in de polders,\n  de culturele en culinaire uitstappen naar Oostende. Bedankt Irma, onze dochters en wijzelf hebben genoten van ons familieweekend bij jou. We voelden ons VIP’s dankzij jou!\n\n"
}, {
    "name": "Vandeput Ann",
    "date": "07-15-2017",
    "score": 10,
    "body": "\n  We genoten van een uniek verblijf en werden er helemaal zen. De regenbuien, die door de streek dankbaar in ontvangst werden genomen, namen we er graag bij. In de plaats daarvan brachten we wat meer tijd door op onze verblijfplaats: de piekfijn ingerichte\n  en gezellige kamer, de rust brengende bibliotheek, de leuke salon-living waar ’s ochtends het overheerlijke, dagelijkse gevarieerde ontbijt werd geserveerd... Dankjewel Irma voor het warme onthaal, je oprechte interesse, de extra’s die ons te beurt\n  vielen... Heel graag tot een volgende keer!\n"
}, {
    "name": "Van Maele Kris - Vanhove Lieve",
    "date": "06-04-2017",
    "score": 10,
    "body": "\n  Wij logeerden er voor de tweede keer. Ruim twee jaar tussen, maar wie terugkeert vond het de vorige keer ook goed. Het weer was schitterend en we genoten ook volop van de tuin. Veel gelopen en gefietst. Maar uiteraard de prachtige kamers en de gastvrouw\n  Irma die zorgt voor een heerlijk ontbijt en wat tussendoortjes maken het verblijf compleet. We laten er niet meer ruim twee jaar tussen, we komen vroeger terug naar Spoor 62.\n"
}, {
    "name": "Schumacher, Brigitte",
    "date": "05-01-2017",
    "score": 10,
    "body": "\n  3 Tage haben wir die überwältigende Gastfreundschaft von Irma Schabon in Spoor62 genossen. Es stimmt einfach alles: Zimmer mit allem Komfort , Lage super, für Jogger und Radfahrer ( direkt hinter dem Haus verläuft die Piste), sensationelles Frühstück,\n  Bibliothek mit Weinbar usw. Hinzukommt die individuelle Betreuung durch Irma mit vielen Tipps für Restaurants und Ausflüge nach Brügge, De Haan ......... Also nichts wie hin und genießen. Absolut empfehlenswert. Brigitte und Frank\n"
}, {
    "name": "Uschi und Bernd Koch",
    "date": "04-25-2017",
    "score": 10,
    "body": "\n  Nach Februar 2016 waren wir jetzt aus gutem Grund wieder für ein tolles Wochenende im spoor 62. wir waren letztes Jahr schon total begeistert von der Atmosphäre. Das tolle Design der Einrichtung, die wunderschönen Zimmer mit gemütlichem Essraum am Kamin\n  und die kleine Bibliothek vermitteln ein perfektes Erholungsgefühl. Das Frühstück war mal wieder ein Genuss und jeden Morgen anders und abwechslungsreich. Irma hat uns wieder perfekt umsorgt und unterhalten. Unsere guten Freunde, Ruth und Mickie, waren\n  natürlich auch wieder dabei . Es hat uns allen super gefallen, ein perfekter Ort um nach dem tollen Frühstück von dort aus Tagesausflüge zu unternehmen und Abends die Ruhe im alten Bahnhof zu genießen. Wir kommen wieder....\n"
}, {
    "name": "Sef en Netty",
    "date": "03-24-2017",
    "score": 10,
    "body": "\n  Voor de 6e keer hebben we spoor 62 geboekt . Iedere keer weer genieten we van ons verblijf. Het super heerlijke ontbijt, de mooie ruime kamer, de rust, het uitstapje naar de zee, het verkennen van de omgeving en de vriendelijke en gastvrije ontvangst\n  van Irma en Jos. Wij blijven terugkomen, dat is zeker. Top adres!\n"
}, {
    "name": "Matei&Silvia",
    "date": "03-14-2017",
    "score": 10,
    "body": "\n  When we arrived, we took a video of the place and sent it to a friend with the caption ’We booked a night in heaven’. Thanks for a magical stay in Belgium. We ’ll be back!\n"
}, {
    "name": "Luc Michèle  Ringoir  De Paepe",
    "date": "08-05-2016",
    "score": 10,
    "body": "\n  Schitterende locatie ,heel ontspannen genieten .Heel lekker ontbijt vriendelijke gastvrouw .Het was gewoon schitterend bedankt .Wij komen zeker terug\n"
}, {
    "name": "van baelenberghe claire",
    "date": "06-25-2016",
    "score": 10,
    "body": "\n  Nog nooit zo dicht bij thuis geslapen, precies 6 kilometer. De reden van mijn bezoek was de moeilijkste en pijnlijkste gebeurtenis in mijn leven; het overlijden van mijn moeder. Mijn grootmoeder van 91, kwam van Engeland over en moest een slaapplaats\n  hebben waarbij er geen hinder van trappen waren, zo kwamen we terecht bij Spoor 62. We werden huiselijk ontvangen op een schitterende locatie. De gastvrouw deed haar uiterst best om ons te troosten in de moeilijkste dagen van ons leven! Bedankt hiervoor!!\n"
}, {
    "name": "Valentine & Olivier",
    "date": "10-04-2015",
    "score": 10,
    "body": "\n  Nous venons de passer les deux dernières nuits dans ce B&B de rêve! Juste deux chambres, une ancienne gare située au milieu de nulle part, décors superbe, une decoration faite avec goût... calme, repos et bien-être assurés. Nous voilà revenus plus\n  détendus que jamais! Le petit déjeuner étai délicieux, tout comme l’acceuil d’Irma qui était des plus chaleureux. Un week-end hors du temps comme on les aime ;-)\n"
}, {
    "name": "Els en Jan Goossens-Coppens",
    "date": "09-28-2015",
    "score": 10,
    "body": "\n  Fantastisch weekend achter de rug met een gastvrouw die het haar gasten helemaal naar hun zin maakt en hen heel welkom laat voelen. Smaakvol en gezellig ingerichte kamers en een ontbijt zoals je het voor jezelf thuis niet maakt. Historiek en natuur liggen\n  op een boogscheut van het gastenverblijf. Een schitterende plek om even tot rust te komen en dus een absolute aanrader!\n"
}, {
    "name": "Chris & Daniël",
    "date": "09-12-2015",
    "score": 10,
    "body": "\n  Soms sta je even stil, als een trein die stopt in het station, om even uit te stappen en gewoon te mijmeren op het perron, te genieten van al datgene wat mooi is en tevens door gesprekken meer diepgang krijgt. Om, na een zee van tijd, terug in te stappen\n  en uitgerust, met meer culturele bagage en inzicht verder te sporen. Soms is dat station, dat rustpunt van waaruit je weer verder kan, het juiste spoor 62.\n"
}, {
    "name": "Staelen ",
    "date": "08-27-2015",
    "score": 10,
    "body": "\n  Luxe, calme et volupté... Ces trois mots caractérisent bien l esprit de notre séjour. Nous pourrions rajouter Irma qui par son accueil, son écoute, ses attentions et bons conseils ont fait de ce séjour un très bon moment. Nous vous recommandons vivement.\n  Dorothee et Stephane\n"
}, {
    "name": "sabine & uwe",
    "date": "08-25-2015",
    "score": 10,
    "body": "\n  liebe irma, lieber jos ... wir kamen für ein »bed & breakfast« und wollten flandern erkunden - und blieben im »spoor 62« hängen ... (traum)-räume ... in denen die seele atmen kann ... (traum)-garten ... zum träumen in ruhe und stille ... (traum)-frühstück\n  ... perfekt in allen details ... und dank irma’s empfehlungen und reservierungen herrliche restaurantbesuche zum geniessen ... selten - oder besser noch nie - haben wir uns so »zuhause« gefühlt wie bei euch ... dankeschön - denn das wochenende fühlte\n  sich wie eine ganze woche an ... und bis bald ...\n"
}, {
    "name": "Ursula< & Fredy Müller",
    "date": "07-03-2015",
    "score": 10,
    "body": "\n  Liebe Irma und Familie Wir hatten bei Ihnen eine unvergessliche und wunderbare Zeit verbracht für die wir uns nochmals herzlich bedanken möchten. Ihr Haus ist ein Juwel wo man die Seele baumeln lassen kann und einfach geniesst. Die Gastfreundschaft und\n  das ausgezeichnete Frühstück machen die Tage im Spoor62 zum Erlebnis. Die vielen guten Tipps von Irma haben uns geholfen nichts wesentliches zu verpassen. Auch dafür nochmals unser herzlichsten Dank Wir kommen gerne wieder und verbleiben bis dahin mit\n  herzlichen Grüssen Ursula & Fredy\n"
}, {
    "name": "Simon & Mandy Hughes",
    "date": "01-31-2015",
    "score": 10,
    "body": "\n  My wife and I arrived at the station, the beautiful Moere Station.Through the wrought iron gates and down the picturesque avenue of trees. This was the start of our wonderland stay at Spoor.62! Met by Irma, our diamond bright hostess who cared for us\n  with every moment a smile. A quick tour of the old station, with every step we melted more and more into relaxation. The surroundings and calm, transforming us into a daze of delight! Time, as time does, goes by too quickly. But especially here, time\n  was timeless. This beautiful station is a place to crave more of, so to the lovely irma and the family of Spoor.62 we shall return for more and more; (Big hug for Hector) Station Moere is in our hearts!!! Much Love, Simon & Mandy\n"
}, {
    "name": "Emilie et Christian",
    "date": "01-28-2015",
    "score": 10,
    "body": "\n  Nous avons passé un week-end parfait! Le calme, le bon goût, le sourire, les conseils, la réalisation architecturale, le petit déjeuner, le comfort.....tout était parfait pour relaxer....se retrouver....décompresser! Irma, un très grand merci et à bientôt\n  (ça c’est certain!!) Emilie et Christian\n"
}, {
    "name": "Frederik en Liesbeth",
    "date": "01-28-2015",
    "score": 10,
    "body": "\n  Het woord \"vertraging\" krijgt hier een heel andere dimensie. Zelden zo graag vertoefd in een stationsgebouw. We appreciëren jullie goede smaak en de oprechte gastvrijheid! Eén ding is zeker : tot weerziens! Frederik & Liesbeth\n"
}, {
    "name": "Sylvie & Robert",
    "date": "01-28-2015",
    "score": 10,
    "body": "\n  Spoor.62 A train station to rest your mind. Où ne s’arrête que le repos. In het vlakkeland van Vlaanderen près du silence des coquelicots. Merci Irma pour votre acceuil! Et à votre mari! Votre maison est magnifique, nous avons passé iun séjour inoubliable!\n  Merci mille fois! Thanks Jos with a special mention to Hector! :) Sylvie et Robert\n"
}, {
    "name": "Bob & Irene",
    "date": "01-28-2015",
    "score": 10,
    "body": "\n  Beste Irma, Bedankt voor een heerlijk verblijf in Gistel. De combinatie van een fantastische omgeving, heerlijke kamer en jouw zichtbare passie, oog voor detail en zorgzaamheid maakt het een heerlijk weekend weg. Een absolute aanrader We hebben genoten,\n  dank je wel. Bob & Irene\n\n"
}, {
    "name": "Chris u Simone",
    "date": "01-28-2015",
    "score": 10,
    "body": "\n  Vielen Dank für die wunderbare Zeit hier! In so schönen Ambience und phantastischer Gastfreundlichkeit! Chris & Simone Freyling\n\n"
}, {
    "name": "Peter en Karen",
    "date": "11-11-2014",
    "score": 10,
    "body": "\n  Kom tot rust aan de kust.... Fantastische locatie, prachtige architectuur en inrichtng van de kamers, heerlijk ontbijt met veel variatie,supervriendelijke en gastvrije Irma, unieke welness -ervaring opgedaan dankzij Irma, heerlijke eetadresjes bezocht...\n  Kortom : we konden onmogelijk alles bezichtigen, daarom moeten we terugkomen...Maar dat doen we met veel plezier. Tot gauw, Irma. Peter en Karen\n"
}, {
    "name": "Kevin & Julie",
    "date": "06-05-2014",
    "score": 10,
    "body": "\n  Un grand merci pour cet acceuil chaleureux. Un endroit magnifique, idéal pour les amoureux, qui permet de mettre de côté les soucis du quotidien et de se retrouver. Etonnement surpris, nous reviendrons! Julie et Kevin de Dinant Bonne continuation\n"
}, {
    "name": "charlotte und stefan",
    "date": "06-05-2014",
    "score": 10,
    "body": "\n  Liebe Irma, Wir hatten heir ein ganz wunderbare Zeit. Ihr haus ist wirklich ein Ort, an dem man die Seele baumeln und den Alltag hinter sich lassen kann. Allerbesten Dank für die Gastfreundschaft, das tolle Frühstück und die vielen Tips. Wir haben uns\n  bestens aufgehoben gefühlt und kommen bestimmt wieder. Alles Liebe, Charlotte und Stefan\n"
}, {
    "name": "Kristien en Joris",
    "date": "06-05-2014",
    "score": 10,
    "body": "\n  Beste irma, Hartelijk dank voor de aangename halte in Spoor62. Eeen levenslustigkader, dicht bij zee (Meestal) wind mee. We raken het spoor niet bijster, we nemen het mee.... Kristien & Joris\n"
}, {
    "name": "Emely en Michel",
    "date": "02-17-2014",
    "score": 10,
    "body": "\n  Wij hebben echt genoten van ons verblijf in uw B&B. Zalig tot rust komen in een prachtige omgeving. Het gaf ons veel inspiratie! Echt proficiat voor de ontvangst, het heerlijke ontbijt en de warmte. Veel succes gewenst in de toekomst! Een echte aanrader.\n  Groeten Michel en Emely\n"
}, {
    "name": "Lieve en Pieter",
    "date": "01-04-2014",
    "score": 10,
    "body": "\n  Schoonheid, een streepje muziek en een goed boek zijn balsem voor de ziel. Een warme, gastvrije én belezen gastvrouw is dat ook... Dank je wel hiervoor! Het was er allemaal! heel oprecht en vol bewondering en verwondering, lieve en pieter\n"
}, {
    "name": "Peter und Marlies",
    "date": "01-04-2014",
    "score": 10,
    "body": "\n  Liebe Irma, vielen Dank für die Gastfreundschaft. Das B&B ist etwas ganz besonders. Wir haben uns wie zu Hause gefühlt. Danke für die tollen Tips und die Zeit...wir kommen gerne wieder\n\n"
}, {
    "name": "Floriane et Vincent",
    "date": "01-01-2014",
    "score": 10,
    "body": "\n  Merci Irma pour avoir rendu notre séjour plus que parfait! Votre présence et vos conseilsont été très appréciés. Le cadre et la tranquillité des lieux sont très reposant, et les chambres sont amènagées avec goût. Nous n’avons vraiment manqués de rien\n  et vos magnifiques petis déjeuners prépares avec soin vont nous manger! es 4 jours en Belgique nous ont vraiment été très agréables et nous espérons revenir a l’occasion. Merci pour tout, ne changez rien! Floriane et Vincent\n"
}, {
    "name": "Stef & Ilse",
    "date": "01-01-2014",
    "score": 10,
    "body": "\n  Beste Irma, Hartelijk dank voor de goede ontvangst. Prachtige B&B, rustig, mooi gebouw. Zeker een plek om terug te komen. We wensen je nog heel veel gasten en we zullen je B&B zeker aanprijzen. Groeten, Stef & Ilse\n"
}, {
    "name": "Pieter & Lisette",
    "date": "01-01-2014",
    "score": 10,
    "body": "\n  Dag Irma, Onze twee dagen hier hebben onze verwachtingen méér dan overtroffen! Je positieve betrokkenheid en goede zorgen, de prachtige natuur en de inrichting waar we met verwondering naar alle details hebben gekeken, in één woord: GEWELDIG. We hopen\n  nog een keer terug te komen, want ... het smaakt vooral naar meer. Heel erg bedankt, Pieter en Lisette\n"
}, {
    "name": "Gaëlle et Emmanuel",
    "date": "12-15-2013",
    "score": 10,
    "body": "\n  Merci Irma, Toujours un plaisir de revenir chez vous. Gentillesse, finesse et raffinement sont toujours les maitres-mots. Au plaisir de revenir, encore. Gaêlle et Emmanuel\n"
}, {
    "name": "Karin und Jani",
    "date": "12-15-2013",
    "score": 10,
    "body": "\n  Liebe Ima, vielen Dank für die vielen Insidertips. Ihr B&B ist mit viel Liebe und Geschmack eingerichtet, man fühlt sich von der 1. Minute an so wohl in ihrem zu Hause. Wir freuen uns auf Gent und denken sicher an dich, Irma. Bis auf ein nächstes\n  Mal, Karin & Jani\n"
}, {
    "name": "Schenk Margarete und Helmut",
    "date": "09-03-2013",
    "score": 10,
    "body": "\n  Ein Höhepunkt in jeder Hinsicht: unsere Urlaubswoche in Flandern: unser Ziel: Kunst und Küste unser Glück: das Quartier bei Irma. Der Weg durchs Gartentor : ein Ruhepol öffnet sich, ein Wohlfühlort. dezent begleitet und versorgt von der Hausherrin. Vielen\n  Dank liebe Irma\n"
}, {
    "name": "Miranda",
    "date": "08-24-2013",
    "score": 10,
    "body": "\n  GENIETEN! met hoofdletters en een uitroepteken. Meer is eigenlijk niet nodig om deze b&b te omschrijven. En uiteraard voor een ieder die dit leest, ga het beleven. Van begin tot het einde is je verblijf op deze bijzondere lokatie tot in de puntjes\n  geregeld, eigenaresse Irma zorgt hier persoonlijk voor. Prachtige kamer (zeker voor diegene die oog voor design hebben}, fantastische bedden en een heerlijk ontbijt, het feit dat je verblijft in een voormalig station maakt dit adres nog meer speciaal.\n  Irma bedankt voor alle goede zorgen! met vriendelijke groet, Edwin en Miranda\n"
}, {
    "name": "Karin und Gisbert",
    "date": "08-18-2013",
    "score": 10,
    "body": "\n  Wir haben die Zeit bei Irma u. Jos genossen. Es war traumhaft, die Stille, das Frühstück von Irma einfach toll. Wir hoffen auf ein Wiedersehen. Karin + Gisbert aus Duderstadt\n"
}]

var slick = require('slick-carousel');

$(".js-hero-slider").each(function() {
  $(this).slick({
    fade: true,
    arrows: false,
    dots: true,
    appendDots: $(this).next('.js-gallery-dots'),
    autoplay: true
  });
});

$(".js-partner-slider").slick({
  centerMode: true,
  variableWidth: true,
  prevArrow: '<button class="slick__arrow slick__arrow--prev"></button>',
  nextArrow: '<button class="slick__arrow slick__arrow--next"></button>'
});

$(".js-quote-slider").slick({
  fade: true,
  prevArrow: '<button class="slick__arrow slick__arrow--prev"></button>',
  nextArrow: '<button class="slick__arrow slick__arrow--next"></button>',
  autoplay: true
});

var $row = $('.js-row-anchor');
if($row.length) {
  $(window).on('scroll', function() {

    $row.each(function() {

      var $anchor = $(this).find('.js-anchor');
      if(!$anchor.length) {
        return;
      }
      var isAtTop = ($(window).scrollTop() - $(this).offset().top) > 0;
      var $rowBot = $(this).offset().top + $(this).outerHeight();
      var isAtBottom = $anchor.offset().top + $anchor.outerHeight() >= $rowBot || $anchor[0].getBoundingClientRect().top < 0;


      if (isAtTop) {
        $(this).addClass('hasStickySlider');
      } else {
        $(this).removeClass('hasStickySlider');
      }

      if (isAtBottom) {
        $top = ($(window).scrollTop() + $(window).height()) - $rowBot;
        if ($top > 0) {
          $anchor.css({
            top: -$top + 'px'
          });
        } else {
            $anchor.css({
              top: '0px'
            });
          }
        }
    })

  });
}

var $bwauto = $('.js-bw-auto');
if ($bwauto.length) {
  setTimeout(function() {
    $bwauto.addClass('isVisible');
  }, 1500);
}

var $bw = $('.js-bw');
if ($bw.length) {
  $(window).on('scroll', function() {
      $bw.each(function() {
        var isVisible = $(window).scrollTop() + ($(window).height() / 2) > $(this).offset().top;
        if (isVisible) {
          $(this).addClass('isVisible');
        }
      });
  });
}

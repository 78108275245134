var PhotoSwipe = require('photoswipe/dist/photoswipe.js');
var PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default.js');

var template="";
    template += "<div class=\"pswp\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">";
    template += "    <div class=\"pswp__bg\"><\/div>";
    template += "    <div class=\"pswp__scroll-wrap\">";
    template += "        <div class=\"pswp__container\">";
    template += "            <div class=\"pswp__item\"><\/div>";
    template += "            <div class=\"pswp__item\"><\/div>";
    template += "            <div class=\"pswp__item\"><\/div>";
    template += "        <\/div>";
    template += "        <div class=\"pswp__ui pswp__ui--hidden\">";
    template += "            <div class=\"pswp__top-bar\">";
    template += "                <div class=\"pswp__counter\"><\/div>";
    template += "                <button class=\"pswp__button pswp__button--close\" title=\"Close (Esc)\"><\/button>";
    template += "                <button class=\"pswp__button pswp__button--share\" title=\"Share\"><\/button>";
    template += "                <button class=\"pswp__button pswp__button--fs\" title=\"Toggle fullscreen\"><\/button>";
    template += "                <button class=\"pswp__button pswp__button--zoom\" title=\"Zoom in\/out\"><\/button>";
    template += "                <div class=\"pswp__preloader\">";
    template += "                    <div class=\"pswp__preloader__icn\">";
    template += "                      <div class=\"pswp__preloader__cut\">";
    template += "                        <div class=\"pswp__preloader__donut\"><\/div>";
    template += "                      <\/div>";
    template += "                    <\/div>";
    template += "                <\/div>";
    template += "            <\/div>";
    template += "            <div class=\"pswp__share-modal pswp__share-modal--hidden pswp__single-tap\">";
    template += "                <div class=\"pswp__share-tooltip\"><\/div>";
    template += "            <\/div>";
    template += "            <button class=\"pswp__button pswp__button--arrow--left\" title=\"Previous (arrow left)\">";
    template += "            <\/button>";
    template += "            <button class=\"pswp__button pswp__button--arrow--right\" title=\"Next (arrow right)\">";
    template += "            <\/button>";
    template += "            <div class=\"pswp__caption\">";
    template += "                <div class=\"pswp__caption__center\"><\/div>";
    template += "            <\/div>";
    template += "        <\/div>";
    template += "    <\/div>";
    template += "<\/div>";

var nodes = Array.prototype.slice.call(document.querySelectorAll('.js-gallery-link'));

if(nodes.length) {
  nodes.forEach(function(thumb) {
    thumb.addEventListener('click', function(e) {
      e.preventDefault();
      var thumbIndex = parseInt(thumb.dataset.index);
      var items = getItems(thumb);
      OpenPswp(thumbIndex, items);
    });
  });
}

function OpenPswp(thumbIndex, items) {
  document.querySelector('.content').insertAdjacentHTML('afterend', template);

  var pswpEl = document.querySelector('.pswp');

  // build items array
  var items = items;

  // define options (if needed)
  var options = {
      index: thumbIndex,
      showHideOpacity: true,
      showAnimationDuration: 0,
      history: false,
      getThumbBoundsFn: function(thumbIndex) {
        // See Options -> getThumbBoundsFn section of documentation for more info
        var thumbnail = items[thumbIndex].thumb;
        var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        var rect = thumbnail.getBoundingClientRect();
        return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
    }
  };

  // Initializes and opens PhotoSwipe
  var gallery = new PhotoSwipe(pswpEl, PhotoSwipeUI_Default, items, options);
  gallery.listen('gettingData', function(index, item) {
    if (item.w < 1 || item.h < 1) { // unknown size
      var img = new Image();
      img.onload = function() { // will get size after load
        item.w = this.width; // set image width
        item.h = this.height; // set image height
        gallery.invalidateCurrItems(); // reinit Items
        gallery.updateSize(true); // reinit Items
      }
    img.src = item.src; // let's download image
    }
  });
  gallery.init();
}

var closestByClass = function(el, clazz) {
    while (!el.classList.contains(clazz)) {
        el = el.parentNode;
        if (!el) {
            return null;
        }
    }
    return el;
}

function getItems(node) {
  var items = [];
  var thumbs = Array.prototype.slice.call(closestByClass(node, 'js-gallery-grid').querySelectorAll('.js-gallery-link'));
  thumbs.forEach(function(node) {
    var thumb = {};
    thumb.thumb = node.querySelector('img');
    thumb.src = node.href;
    thumb.w = 0;
    thumb.h = 0;
    thumb.msrc = node.querySelector('img').src;
    items.push(thumb);
  });
  return items;
};
